<template>
	<div id="pageList" class="el-content">
		<router-link to="/website/webPage">
			<a-button type="primary" v-has="{action:'/website/webPage'}">新增页面</a-button>
		</router-link>
		<a-table :pagination="false" row-key="id" :data-source="info.list" :columns="[
			{title:'ID',dataIndex:'id'},
			{title:'页面名称',dataIndex:'name'},
			{title:'是否默认',dataIndex:'sign',slots:{customRender:'sign'}},
			{title:'创建时间',dataIndex:'create_time'},
			{title:'最后更新时间',dataIndex:'update_time'},
			{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
		]" >
			<template #sign="{record}">
				<a-tag v-if="record.sign!= 'home'" color="#999">否</a-tag>
				<a-tag v-else color="#00CC66">默认</a-tag>
			</template>
			<template #action="{record}">
				<a-space>
					<router-link :to="{path:'/website/webPage',query:{id:record.id}}">
						<a-button
							type="link" 
							size="small" 
							v-has="{action:'/website/webPage'}">编辑
						</a-button>
					</router-link>
					<a-button 
						type="link" 
						size="small" 
						v-if="record.sign!= 'home'" 
						@click="setDefault(record.id)"  
						v-has="{action:'wxapp_set_homePage_default'}">设置默认
					</a-button>
					<a-button 
						v-if="record.is_default!= 'home'" 
						type="link" 
						@click="deletePage(record.id)" 
						size="small" 
						v-has="{action:'wxapp_set_homePage_del'}">删除
					</a-button>
				</a-space>
			</template>
		</a-table>
		<div class="pager">
			<a-pagination
				show-size-changer
				:default-current="info.page"
				:total="info.count"
				@showSizeChange="(p,e)=>{getWebsitePageList(info.page,e)}"
				@change="(e)=>{getWebsitePageList(e,info.limit)}"
			/>
		</div>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import customPageModel from '@/api/customPage'
export default{
	setup(){
		const state = reactive({
			key:'',
			info:{
				list:[],
				page:1,
				count:0,
				limit:10
			},
		})
		getWebsitePageList(1,state.info.limit)

		function getWebsitePageList(page,limit){
			customPageModel.getPcPage(page,limit,{key:state.key},res=>state.info = {limit,...res})
		}

		const setDefault = id=>customPageModel.setPcPageDefault(id,()=>{
			getWebsitePageList(state.info.page,state.info.limit)
		})

		const copyList = id=>customPageModel.pcPageCopy(id,()=>{
			getWebsitePageList(state.info.page,state.info.limit)
		})

		const deletePage = id=>customPageModel.deletePcPage([id],()=>{
			getWebsitePageList(state.info.page,state.info.limit)
		})

		return{
			...toRefs(state),
			getWebsitePageList,
			setDefault,
			copyList,
			deletePage
		}
	}
}
</script>

<style>
</style>
